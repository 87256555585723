import React, { useEffect, useState } from 'react';
import { IMAGE_CIRCLES } from '../../../assets';
import { RandomReveal } from 'react-random-reveal';
import Lottie from 'react-lottie';
import * as animationData from '../../../assets/Json/FLOATINGMAN.json';
import FooterMobile from '../FooterMobile';
import { PAGE_MOBILE } from '../../../constants';
import CustomRandomReveal from '../../../ultils/CustomRandomReveal';

interface Props {
  footerType: number;
  handlePrevStep: () => void;
  handleNextStep: () => void;
}

export default function Intro(props: Props) {
  const { handlePrevStep, handleNextStep } = props;

  useEffect(() => {
    const handleScroll = (e: any) => {
      e.preventDefault();
    };

    document.body.style.overflow = 'hidden';
    window.addEventListener('scroll', handleScroll, { passive: false });

    return () => {
      document.body.style.overflow = 'auto';
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const _renderTextLoad = (title: string) => {
    return title
  };

  const _renderTextName = (titles: string[]) => {
    const [count, setCount] = useState(0);
    const caret = document.getElementsByClassName("typewriter-container")[0]

    return (
      <span className="typewriter-container pause">
        <CustomRandomReveal
          previous={titles[count]}
          next={titles[count == titles.length - 1 ? 0 : count + 1]}
          interval={100}
          delay={2}
          caret={caret}
          onComplete={() => {
            if (count == titles.length - 1) {
              setCount(0);
            }
            else {
              setCount(count + 1);
            }
          }}
        />
      </span>
    )
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const screenWidth = window.innerWidth || document.documentElement.clientWidth;

  const _renderContent = () => {
    return (
      <div className='intro-container-mobile'>
        <p className='text-gray letter-scrambler'>{`< Intro >`}</p>
        <div>
          <span className='description-intro-mobile letter-scrambler'>
          We run on experimentation and creativity.Invented from Advertising and Production spheres,
          The Other Lab thrives on materialising ideas with a team of highly skilled
          </span>
          <span style={{ whiteSpace: 'pre-wrap' }}> </span>{_renderTextName(['creatives', 'suits', 'developers', 'programmers', 'artists', 'illustrators', 'designers'])}
        </div>
        <p className={'text-gray-carrot'}>{'<>'}</p>
        <div className="image-group-container">
          <p className='text-start-intro letter-scrambler'>CONCOCTING</p>
          <p className='text-end-intro letter-scrambler'>CHAOS</p>
          <img src={IMAGE_CIRCLES} alt={""} className={'image__circle-home-intro'} />
          <div className={'image-home-intro'}>
            <Lottie
              options={defaultOptions}
              width={'100%'}
              height={'auto'}
              isClickToPauseDisabled
            />
          </div>
        </div>
      </div>
    )
  };

  return (
    <div className={'div-loading-container'}>
      {_renderContent()}
      {/*<FooterMobile*/}
      {/*  footerType={PAGE_MOBILE.INTRO}*/}
      {/*  handlePrevStep={handlePrevStep}*/}
      {/*  handleNextStep={handleNextStep}*/}
      {/*/>*/}
    </div>
  )
}
