import { BrowserRouter, Route, Routes } from "react-router-dom";
import NotFoundRouter from "./NotFoundRouter"
import Mobile from "../layout/Mobile";
import HomeMobile from "../component/Mobile/Home";
import ProjectMobileDetail from "../component/Mobile/ProjectDetailMobile";
import { TransitionProvider } from "../component/Mobile/Transition/TransitionContext";
import TransitionComponent from "../component/Mobile/Transition/Transition";

const RouterMobile = () => {
  return (
    <BrowserRouter>
      <TransitionProvider>
        <Routes>
          <Route path="*" element={<NotFoundRouter />} />
          <Route path={'/'} element={<Mobile />}>
            <Route path={''}
              element={
                <TransitionComponent up={true}>
                  <HomeMobile />
                </TransitionComponent>
              }
            />
            <Route path={'project-detail/:id'}
              element={
                <TransitionComponent up={false}>
                  <ProjectMobileDetail />
                </TransitionComponent>
              }
            />
          </Route>
        </Routes>
      </TransitionProvider>
    </BrowserRouter>
  )
}

export default RouterMobile
