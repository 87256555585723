import './footer-mobile.scss';
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { PAGE_MOBILE } from "../../../constants";
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import { useRef } from 'react';
import ChangeAudioMute, { SetAudioMute } from '../../../ultils/Audio';
import SwipeComponent from '../swipe';

interface Props {
  footerType: number;
  footerColor: string | void;
  handlePrevStep: () => void;
  handleNextStep: () => void;
  goToLast: () => void;
  goToDetail: () => void;
}

const FooterMobile = (props: Props) => {
  const audioRef = useRef<any>(null);
  const { footerType, footerColor, handlePrevStep, handleNextStep, goToDetail } = props;
  const listProject = useSelector((state: RootState) => state.projectList);

  const _changeAudioMute = () => {
    ChangeAudioMute(audioRef, '.video-container video');
  }

  const _renderFooterTitle = () => {
    return (
      <>
        <div className='footer-mobile-border'></div>
        <div className={'footer-mobile-container'}>
          <div className={`footer-intro-mobile`}>
            <div className='text-footer-mobile'>
              <div className='footer-button' onClick={_changeAudioMute}>
                <div className='audio-indicator-mobile' >
                  <div>SOUND</div>
                  <span ref={audioRef} className='audio-icon'>OFF</span>
                </div>
              </div>
              <div className='footer-mid'></div>
              <div className='footer-button' onClick={handleNextStep}>
                <RightOutlined className="icon-transfer" style={{ mixBlendMode: 'unset' }} />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  };

  const _renderFooterIntro = () => {
    return (
      <>
        <div className='footer-mobile-border'></div>
        <div className={'footer-mobile-container'}>
          <div className={`footer-intro-mobile`}>
            <div className={`text-footer-mobile`}>
              <div className='footer-button' onClick={handlePrevStep}>
                <LeftOutlined className="icon-transfer" />
              </div>
              <p className='letter-scrambler'>
                Experimentation in progress...
              </p>
              <div className='footer-button' onClick={handleNextStep}>
                <RightOutlined className="icon-transfer" />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  };

  const _renderFooterLoading = () => {
    SetAudioMute('.audio-icon', '.video-container video', true);
    return (
      <>
        <div className='footer-mobile-border'></div>
        <div className={'footer-mobile-container'}>
          <div className={`footer-intro-mobile`}>
            <div className={`text-footer-mobile`}>
              <div className='footer-button' onClick={handlePrevStep}>
                <LeftOutlined className="icon-transfer" />
              </div>
              <p className='letter-scrambler'>
                A small lab with big experiments.
              </p>
              <div className='footer-button' onClick={handleNextStep}>
                <RightOutlined className="icon-transfer" />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  };

  const _renderFooterProject = () => {
    return (
      <>
        <div className='footer-mobile-border' style={{ borderColor: 'transparent' }}></div>
        <div className={'footer-mobile-container'}>
          <div className={`footer-intro-mobile`}>
            <div className={`text-footer-mobile`}>
              <div className='footer-button' onClick={handlePrevStep}>
                <LeftOutlined className="icon-transfer" />
              </div>
              <div
                className='footer-mobile-arrows'
                style={{ "--footer-color": footerColor } as React.CSSProperties}
              >
                <SwipeComponent goToDetail={goToDetail}></SwipeComponent>
                <span></span>
                <span></span>
                <p className={'text-view-mobile letter-scrambler'}>VIEW THE WORK</p>
              </div>
              <div className='footer-button' onClick={handleNextStep}>
                <RightOutlined className="icon-transfer" />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  };

  const _renderFooterReach = () => {
    return (
      <>
        <div className='footer-mobile-border'></div>
        <div className={'footer-mobile-container'}>
          <div className={`footer-intro-mobile`}>
            <p className={`text-footer-mobile`}>
              <div className='footer-button' onClick={handlePrevStep}>
                <LeftOutlined className="icon-transfer" />
              </div>
              <div className='footer-mid'></div>
              <div className='footer-button' onClick={handleNextStep}>
                <RightOutlined className="icon-transfer" />
              </div>
            </p>
          </div>
        </div>
      </>
    )
  }

  const _renderFooter = () => {
    switch (footerType) {
      case PAGE_MOBILE.TITLE:
        return _renderFooterTitle();
      case PAGE_MOBILE.LOADING:
        return _renderFooterLoading();
      case PAGE_MOBILE.INTRO:
        return _renderFooterIntro();
      case PAGE_MOBILE.REACH + listProject.length - 1:
        return _renderFooterReach();
      default:
        return _renderFooterProject();
    }
  };

  return (
    <div className='footer-mobile'>
      {_renderFooter()}
    </div>
  )

};

export default FooterMobile;
