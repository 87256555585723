import { Col, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import './list-project.scss';
import LineHome from "../HomeContent/LineHome";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import ProjectItem from "./ProjectItem";
import { RandomReveal } from "react-random-reveal";

const ListProject = () => {
  const width = useSelector((state: RootState) => state.sizeContent.width);
  const height = useSelector((state: RootState) => state.sizeContent.height);
  const widthTd = height * 0.8 / 3;
  const projectList = useSelector((state: RootState) => state.projectList);
  const ref = useRef<any>();

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const _renderHeader = () => {
    return (
      <div
        className={'header-list-project'}
        style={{
          height: widthTd,
          '--padding-vertical': `${widthTd * 0.5}px`,
        } as React.CSSProperties}
      >
        <div className='title-experiments' style={{ fontSize: width / 20 }}>
          Experiments
          {/* <RandomReveal
            isPlaying
            key={'e'}
            duration={Math.random() * 1 + 0.5}
            updateInterval={0.1}
            characters={'e'}
            revealDuration={1}
            onComplete={() => {
              return { shouldRepeat: true, delay: 1 }; // Repeat after 5 - 10 seconds
            }}
            characterSet={["A", "B", "C", "D", "E", "F", "G", "H", "K", "L", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "X", "Y", "Z"]}
            // characterSet={["E", "T", "Z"]}
          /> */}
          
        </div>
        <p
          className={'description-experiments letter-scrambler'}
          style={{
            fontSize: width / 180
          }}
        >
          "TO EXPERIMENT IS TO INVITE CHALLENGES, AND SOMETIMES, CHAOS."
        </p>
      </div>
    )
  };

  const _renderListProject = () => {
    return (
      <Row gutter={0} className={'list-project-bg'}>
        {projectList.map((item, index) => {
          return (
            <Col
              xs={6} // This value here changes the number of items in a row, it is the col size

              key={index}
              className={'col-item-project'}
              style={{
                height: widthTd
              }}
            >
              <ProjectItem project={item} index={index} />
            </Col>
          )
        })}
      </Row>
    );
  };

  return (
    <div ref={ref}
      className="list-project-container"
      style={{
        //  minHeight: 3 * widthTd,
        height: height * 0.8
      }}>
      {_renderHeader()}
      {_renderListProject()}
      <LineHome
        row={Math.ceil(projectList.length / 4)}
        isList={true}
      />
    </div>
  )
};

export default ListProject;
