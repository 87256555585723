import './project-mobile-detail.scss';
import { WarningOutlined } from "@ant-design/icons";
import * as animationData from '../../../assets/Json/BLOB.json';
import Lottie from 'react-lottie';
import { getSettingGifDefault } from "../../../ultils/getSettingGif";
import { ProjectModel } from "../../../models/Project";
import ImageBase from '../../../elements/Image';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { RouterName } from '../../../enum/RouterEnum';
import { RandomReveal } from 'react-random-reveal';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import api from '../../../api';

const ProjectMobileDetail = () => {
  const { id } = useParams();
  const location = useLocation();
  const [project, setProject] = useState({} as ProjectModel);
  const projectList = useSelector((state: RootState) => state.projectList);

  const navigate = useNavigate();

  let touchStartY = 0;
  let touchEndY = 0;
  const projectRef = useRef<HTMLDivElement>(null);

  const handleTouchStart = (e: React.TouchEvent) => {
    touchStartY = e.touches[0].clientY; // Get initial Y position
  };

  const handleTouchEnd = (e: React.TouchEvent) => {
    touchEndY = e.changedTouches[0].clientY; // Get final Y position
    handleSwipe();
  };

  const handleSwipe = () => {
    const swipeDistance = touchStartY - touchEndY; // Calculate distance swiped
    const body = projectRef.current?.parentElement;
    if (body) {
      const atTopOfScroll = body.scrollTop <= 0;
      // alert(projectRef.current?.parentElement?.parentElement?.scrollTop);
      if (atTopOfScroll && swipeDistance < -150) {
        // console.log("Swipe Down detected!");
        goBack();
      }
    }

  };

  useEffect(() => {
    if (location.state) {
      setProject(location.state);
    }
    else if (projectList) {
      let charExits = /\W/g;
      const state = projectList.find(item => item.title.replaceAll(" ", "_").replace(charExits, '') === id)
      location.state = state;
    }
    else {
      _getAllProject().then();
      location.state = project;
    }
  }, [location.state, id]);

  const _getAllProject = async () => {
    try {
      const res = await api.project.getAllProject();
      let charExits = /\W/g;
      const state = res.data.find((item: ProjectModel) => item.title.replaceAll(" ", "_").replace(charExits, '') === id)
      setProject(state);
    } catch (err) {
      // If cannot retrieve from database, fallback to local saves
      console.error("Cannot retrieve data from database...");
    }
  };

  const goBack = () => {
    navigate(`${RouterName.dashboard}`, {
      state: project
    });
  };

  useEffect(() => {
    const handleScroll = (e: any) => {
      e.preventDefault();
    };

    document.body.style.overflow = 'hidden';
    window.addEventListener('scroll', handleScroll, { passive: false });

    return () => {
      document.body.style.overflow = 'auto';
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const _renderTextLoad = (title: string) => {
    return (
      <RandomReveal
        isPlaying
        duration={2}
        characters={title}
        revealDuration={1}
        onComplete={() => ({ shouldRepeat: true, delay: 5 })}
      />
    )
  };

  const _renderImageProject = (link: string, index: number) => {
    return (
      <div>
        <lottie-player
          autoplay
          loop
          mode="normal"
          src={link}
          key={index}
          style={{
            padding: '10px',
            width: "100%"
          }}
        />
      </div>

    )
  };

  const _renderImage = (link: string, index: number) => {
    return (
      <ImageBase
        src={link}
        alt={""}
        className={'project-image'}
        key={index}
      />
    )
  };

  const _renderVideo = (link: string, index: number) => {
    return (
      <video className={'project-image'}
        autoPlay
        loop muted
        key={index}
        playsInline
        src={link}
      />
    )
  };

  return (
    <div className={'project-mobile-detail'}
      ref={projectRef}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <div className='overlay'
        style={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          zIndex: '1'
        }}
      >
      </div>
      <div className={'project-description-container'} id={'header-project-detail-mobile'}>
        <p className={'text-experiment'}>EXPERIMENT</p>
        <p className={'text-name-project'}>{project?.detailTitle}</p>
        <p className={'text-description-project'}>{project?.detailDescription}</p>
      </div>
      {project
        && project.detailImages
        && project.detailImages.map((item, index) => {
          if (item.includes('.json')) {
            return _renderImageProject(item, index)
          } else if (item.includes('.mp4')) {
            return _renderVideo(item, index)
          } else {
            return _renderImage(item, index)
          }
        })}
      <div className='end-project-item'>
        <WarningOutlined className='icon-warning' />
        End of Experiment
      </div>
      <div className='project-mobile-detail-footer'>
        <div className='image-mobile-footer'>
          <Lottie
            options={getSettingGifDefault(animationData)}
            width={'100%'}
            height={'auto'}
            isClickToPauseDisabled
          />
        </div>
        <button className='button-back-to-top' onClick={goBack}>
          <span className={'icon-flash-end'}>{"<<< "}</span>
          {_renderTextLoad('Back to Experiments')}
        </button>
      </div>
    </div>
  )
};

export default ProjectMobileDetail;
