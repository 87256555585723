interface Props {
  element: HTMLElement;
  letter: string;
  index: number;
}

export class LetterScrambleSingleton {
  private static instance: LetterScrambleSingleton;
  private elements: HTMLElement[];
  private scrambleMap: string[][] = [
    ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "x", "y", "z"]
  ];
  private intervalId: number | null;
  private updateInterval: number = 0.05;
  private duration: number = 1;

  private constructor(className: string) {
    this.intervalId = null;
    this.elements = Array.from(document.getElementsByClassName(className)) as HTMLElement[];
  }

  public static getInstance(): LetterScrambleSingleton {
    if (!LetterScrambleSingleton.instance) {
      LetterScrambleSingleton.instance = new LetterScrambleSingleton('letter-scrambler');
    }

    return LetterScrambleSingleton.instance;
  }

  private getRandomElement(): HTMLElement | null {
    if (this.elements.length === 0) return null;
    return this.elements[Math.floor(Math.random() * this.elements.length)];
  };

  private getRandomLetter(): Props | null {
    const element = this.getRandomElement();
    if (!element) {
      console.warn("Warning: No such element!");
      return null;
    }

    const text = element.textContent?.trim() || "";
    if (text.length === 0) {
      console.warn("Warning: Text length is zero!");
      return null;
    }

    const index = Math.floor(Math.random() * text.length);
    const letter = text[index];

    return /[a-zA-Z]/.test(letter) ? { element, letter, index } : null;
  }

  private scrambleLetter(letter: string): string[] | null {
    const lowerLetter = letter.toLowerCase();
    const group = this.scrambleMap.find((group) => group.includes(lowerLetter));

    if (!group) return null;

    // Check if the original letter was uppercase
    const isUppercase = letter === letter.toUpperCase();

    // If the original letter was uppercase, return the container in uppercase
    const scrambledGroup = isUppercase ? group.map((char) => char.toUpperCase()) : group;

    return scrambledGroup;
  }

  private runInterval(): void {
    const interval = Math.floor(Math.random() * 2501) + 2500;
    // const interval = 100;
    this.intervalId = window.setTimeout(this.runOperation.bind(this), interval);
  }

  private runOperation(): void {
    const randomLetterData = this.getRandomLetter();
    if (randomLetterData) {
      const scrambledGroup = this.scrambleLetter(randomLetterData.letter);
      if (scrambledGroup) {
        this.duration = Math.random() * 1 + 0.5;
        // console.log(`Original letter: "${randomLetterData.letter}", Scrambled group: ${scrambledGroup}, Duration: ${this.duration.toFixed(2)}`);
        this.randomReveal(randomLetterData.element, scrambledGroup, this.duration, this.updateInterval, randomLetterData.letter, randomLetterData.index);
      }
      else {
        this.runOperation();
      }
    }
    else {
      this.runOperation();
    }
  }

  public startRandomOperations(): void {
    this.stopRandomOperations();

    // Ensure there is elements
    if (this.elements.length === 0) {
      this.elements = Array.from(document.getElementsByClassName('letter-scrambler')) as HTMLElement[];
      if (this.elements.length === 0) {
        return;
      }
    }

    if (this.intervalId !== null) {
      // console.log("Random operations are already running.");
      return;  // Prevent starting multiple intervals
    }

    this.intervalId = 0;
    this.runOperation();
  }

  public stopRandomOperations(): void {
    if (this.intervalId === null) {
      // console.log("No random operations are running.");
      return;
    }

    window.clearInterval(this.intervalId);
    this.intervalId = null;  // Reset the interval ID to null after clearing the interval
    // console.log("Random operations stopped.");
  }

  private randomReveal(element: HTMLElement, characterSet: string[], duration: number, updateInterval: number, characters: string, index: number): void {
    const totalChars = characters.length;
    const finalText = element.textContent;
    let currentChars = characters;
    const textBeforeLetter = finalText?.slice(0, index);
    const textAfterLetter = finalText?.slice(index + 1);

    let startTime = Date.now();
    let lastUpdateTime = Date.now();

    // Function to update the character reveal
    const updateRandomChars = () => {
      const timeElapsed = Date.now() - startTime;
      const remainingTime = (duration * 1000) - timeElapsed;

      // If the duration has passed, stop the effect and reveal the final text
      if (remainingTime <= 0 || currentChars === null) {
        element.textContent = finalText; // Reveal the final text
        this.runInterval();
        return;
      }

      // Update every frame if updateInterval is 0, otherwise update periodically based on the interval
      const now = Date.now();
      if (updateInterval === 0 || now - lastUpdateTime >= updateInterval * 1000) {
        // Update the displayed characters randomly from the character set

        currentChars = Array.from({ length: totalChars }, () => characterSet[Math.floor(Math.random() * characterSet.length)]).join('');
        element.textContent = textBeforeLetter + currentChars + textAfterLetter;
        lastUpdateTime = now;
      }

      // Call the update function recursively for smooth animation
      requestAnimationFrame(updateRandomChars);
    }

    // Start the random reveal process
    updateRandomChars();
  }

}