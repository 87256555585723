const Processing = () => {
  return (
    <div className='content-left-box' style={{
      borderBottom: 'none',
      justifyContent: 'end',
    }}>
      <div className="box-left-content">
        <p className='text-gray letter-scrambler'>{'< Trust The Process >'}</p>
        <p className="letter-scrambler">
          To experiment is to invite challenges, and sometimes, chaos.
        </p>
        <br />
        <p className="letter-scrambler">
          And now, we're inviting you to the lab to watch it all happen.
        </p>
        <p className='text-gray'>{'<>'}</p>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
          <span className="letter-scrambler">
            Awaiting next steps ...
          </span>
          <span className="span-text-loading">{'>>>'}</span>
        </div>
      </div>
    </div >
  )
};

export default Processing;
