interface Props {
  goToDetail: () => void;
}

const SwipeComponent = (props: Props) => {
  const { goToDetail } = props;
  let touchStartY = 0;
  let touchEndY = 0;

  const handleTouchStart = (e: React.TouchEvent) => {
    touchStartY = e.touches[0].clientY; // Get initial Y position
  };

  const handleTouchEnd = (e: React.TouchEvent) => {
    touchEndY = e.changedTouches[0].clientY; // Get final Y position
    handleSwipe();
  };

  const handleSwipe = () => {
    const swipeDistance = touchStartY - touchEndY; // Calculate distance swiped

    if (swipeDistance > 100) {
      // console.log("Swipe Up detected!");
      goToDetail();
    }
  };

  return (
    <div
      className='swipe-up'
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
    </div>
  );
};

export default SwipeComponent;
