import React, { useEffect, useRef, useState } from "react";
import './home-mobile.scss';
import LoadingCapabilities from "./LoadingCapabilities";
import Intro from "./Intro";
import Reach from "./Reach";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { PAGE_MOBILE } from "../../../constants";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { ProjectModel } from "../../../models/Project";
import { useLocation, useNavigate } from "react-router-dom";
import ProjectMobileItem from "../ListProject/ProjectMobileItem";
import FooterMobile from "../FooterMobile";
import Title from "./Title";
import { RouterName } from "../../../enum/RouterEnum";

const HomeMobile = () => {
  const swiperRef = useRef<any>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const listProject = useSelector((state: RootState) => state.projectList);
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number | null>(null);
  const [footer, setFooter] = useState(PAGE_MOBILE.LOADING);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const _getCurrentProject = () => {
    if (currentSlideIndex) {
      const project: ProjectModel | undefined = listProject.find(item => item.order === currentSlideIndex - PAGE_MOBILE.PROJECTS);
      return project;
    }

    return undefined;
  }

  const _changeShowDetail = () => {
    const project = _getCurrentProject();
    if (project) {
      let charExits = /\W/g;
      const path = project.title.replaceAll(" ", "_").replace(charExits, '');
      navigate(`${RouterName.projectDetail}/${path}`, {
        state: project
      })
    }
  };


  useEffect(() => {
    if (location.state) {
      const projectActive: ProjectModel = location.state;
      const index = listProject.findIndex(item => item.id === projectActive.id);
      if (index > -1) {
        swiperRef.current.slideToLoop(index + PAGE_MOBILE.PROJECTS, 0);
      }
    }
  }, [location.pathname]);

  const changeToPageLast = () => {
    swiperRef.current.slideToLoop(PAGE_MOBILE.REACH + listProject.length - 1);
  };

  const _handleNextStep = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  const changeSwiper = (swiper: any) => {
    setCurrentSlideIndex(swiper.realIndex);
    setFooter(swiper.realIndex);
  };

  const _handlePrevStep = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  useEffect(() => {
    const handleResize = () => setWindowHeight(window.innerHeight);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const _renderHeader = () => {
    const project = _getCurrentProject();
    if (project) {
      return (
        <div className="header-slide-container" style={{["--window-height" as string]: `${windowHeight}px`}}>
          <p className='text-title-mobile'>CLIENT</p>
          <p className='text-title-desc-mobile'>{project.title}</p>
          <p className='text-detail-mobile'>EXPERIMENT</p>
          <p className='text-detail-desc-mobile'>{project.detailTitle}</p>
        </div>
      );
    } else {
      return null;
    }
  };

  const _renderProjectItem = (item: ProjectModel, index: number) => {
    return (
      <SwiperSlide virtualIndex={index + 2} key={index}>
        <ProjectMobileItem
          index={index}
          project={item}
          key={item.id}
          handlePrevStep={_handlePrevStep}
          handleNextStep={_handleNextStep}
          goToDetail={_changeShowDetail}
          realIndex={currentSlideIndex}
        />
      </SwiperSlide>
    )
  };

  const _renderFooter = () => {
    return (
      <FooterMobile
        footerType={footer}
        footerColor={_getCurrentProject()?.titleColor}
        handlePrevStep={_handlePrevStep}
        handleNextStep={_handleNextStep}
        goToLast={changeToPageLast}
        goToDetail={_changeShowDetail}
      />
    )
  };

  return (
    <div className="home-intro-mobile">
      {_renderHeader()}
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        className='swiper-container'
        ref={swiperRef}
        onSwiper={(swiper: any) => (swiperRef.current = swiper)}
        onSlideChange={changeSwiper}
        loop
        onActiveIndexChange={(swiper: any) => {
          swiper.allowTouchMove = swiper.realIndex !== PAGE_MOBILE.TITLE;
          // console.log("Current index: ", swiper.realIndex);

          const contentMobileElement = document.querySelector('.content-mobile') as HTMLDivElement;
          if (contentMobileElement) {
            contentMobileElement.style.height = swiper.realIndex !== PAGE_MOBILE.TITLE ? '90%' : '100%';
            contentMobileElement.style.position = swiper.realIndex !== PAGE_MOBILE.TITLE ? 'relative' : 'absolute';
          }
        }}
      >
        <SwiperSlide virtualIndex={PAGE_MOBILE.TITLE} className="title-panel">
          <Title
            key={PAGE_MOBILE.TITLE}
            handleNextStep={_handleNextStep}
          />
        </SwiperSlide>
        <SwiperSlide virtualIndex={PAGE_MOBILE.LOADING}>
          <LoadingCapabilities
            key={PAGE_MOBILE.LOADING}
            handleNextStep={_handleNextStep}
            handlePrevStep={_handlePrevStep}
          />
        </SwiperSlide>
        <SwiperSlide virtualIndex={PAGE_MOBILE.INTRO}>
          <Intro
            key={PAGE_MOBILE.INTRO}
            footerType={PAGE_MOBILE.INTRO}
            handleNextStep={_handleNextStep}
            handlePrevStep={_handlePrevStep}
          />
        </SwiperSlide>
        {listProject.map((item, index) => {
          return _renderProjectItem(item, index)
        })}
        <SwiperSlide virtualIndex={PAGE_MOBILE.REACH + listProject.length}>
          <Reach
            key={PAGE_MOBILE.REACH}
            footerType={PAGE_MOBILE.REACH}
            handleNextStep={_handleNextStep}
            handlePrevStep={_handlePrevStep}
          /></SwiperSlide>
      </Swiper>
      {_renderFooter()}
    </div>
  )
};

export default HomeMobile;
