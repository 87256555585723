import React, { useEffect, useRef, useState } from 'react'
import { RandomReveal } from 'react-random-reveal';
import titleVideo from '../../../assets/video/MobileShowreel.mp4';
import titleImage from '../../../assets/image/MobileBG.png';
import Canvas from '../../Desktop/Title/canvas';

interface Props {
  handleNextStep: () => void;
}

export default function Title(props: Props) {
  const { handleNextStep } = props;

  const canvasRef = useRef<Canvas | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);


  useEffect(() => {
    // Initialize the Canvas instance
    // if(!canvasRef.current) {
    //   canvasRef.current = new Canvas();
    //   console.log(canvasRef);
    // }

    const renderCanvas = () => {
      if (canvasRef.current) {
        canvasRef.current.render();
        requestAnimationFrame(renderCanvas);
      }
    };

    // Start the animation loop
    renderCanvas();

    return () => {
      canvasRef.current = null;
    }
  }, []);

  useEffect(() => {
    const video = document.querySelector('.video-container video') as HTMLVideoElement | null;

    const renderCanvas = () => {
      if (canvasRef.current) {
        canvasRef.current.render();
        requestAnimationFrame(renderCanvas);
      }
    };

    function setupRenderCanvas() {
      if (containerRef.current && !canvasRef.current) {
        canvasRef.current = new Canvas(1);
        // console.log("Canvas:",canvasRef);
        renderCanvas();
      }
    }

    if (video) {
      video.addEventListener('playing', setupRenderCanvas);
    }

    return () => {
      if (video) {
        video.removeEventListener('playing', setupRenderCanvas);
      }
    };
  }, []);

  useEffect(() => {
    const handleTouchMove = (e: any) => {
      e.preventDefault();
    };

    document.body.style.overflow = 'hidden';
    window.addEventListener('touchmove', handleTouchMove, { passive: false });

    return () => {
      document.body.style.overflow = 'auto';
      window.removeEventListener('touchmove', handleTouchMove);
    };
  }, []);

  const _renderTextLoad = (title: string) => {
    return (
      <RandomReveal
        isPlaying
        duration={2}
        characters={title}
        revealDuration={1}
      />
    )
  };

  const _renderContentItem1 = () => {
    return (
      <div className="home-loading-mobile">
        <p className='text-gray'>{_renderTextLoad('< Loading Capabilities >')}</p>
        <p className='text-gray'>{'<>'}</p>
      </div>
    )
  };

  const _renderContentItem2 = () => {
    return (
      <div className="home-loading-mobile">
        <p className='text-gray'>{_renderTextLoad('< Trust The Process >')}</p>
        <p>
          {_renderTextLoad('To experiment is to invite challenges,')}
          <br />
          {_renderTextLoad('and sometimes, chaos.')}
          <br />
          <br />
          {_renderTextLoad("And now, we're inviting you to the lab")}
          <br />
          {_renderTextLoad("to watch it all happen. ")}
        </p>
        <p className='text-gray'>{'<>'}</p>
        <p
          style=
          {{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          {_renderTextLoad('Awaiting next steps ...')}
          <span className="span-text-loading">{'>>>'}</span>
        </p>
      </div>
    )
  };

  return (
    <div className={'div-loading-container'}>
      <div className='title-mobile' ref={containerRef}
        style={{
          height: window.innerHeight,
          width: window.innerWidth,
          // '--offset-from-top': `${-(window.innerHeight - heightContent) * 0.5}px`,
          // '--offset-from-left': `${-(window.innerWidth - widthContent) * 0.5}px`,
        } as React.CSSProperties}
      >
        <div id='app'>
          <div className='container'>
            <div className='wrapper'>
              <div className='video-container'>
                <video className='title-video-player'
                  autoPlay
                  loop
                  muted
                  playsInline
                  src={titleVideo}
                  data-hover={titleImage}
                  style={{
                    width: window.innerWidth,
                    height: window.innerHeight
                  }}
                />
              </div>
            </div>
          </div>
          <canvas id="webgl" />
        </div>
      </div>
    </div>
  )
};

