let muted: boolean = true;

export const ChangeAudioMute = (audioRef: any, htmlQuery: string) => {
  const video = document.querySelector(htmlQuery) as HTMLVideoElement | null;
  if (audioRef.current && video) {
    muted = !muted;
    video.volume = 1;
    video.muted = muted;
    if (video.muted) {
      audioRef.current.textContent = "OFF";
    } else {
      audioRef.current.textContent = "ON";
    }
  }
}

export const SetAudioMute = (audioRef: any, htmlQuery: string, setMuted: boolean) => {
  const video = document.querySelector(htmlQuery) as HTMLVideoElement | null;
  const audioText = document.querySelector(audioRef) as HTMLSpanElement | null;
  if (video) {
    const fadeOut = () => {
      let volume = video.volume;
      video.volume = Math.max(volume - 0.01, 0);

      if (video.volume === 0) {
        muted = setMuted;
        video.muted = muted;

        if (audioText) {
          audioText.textContent = 'OFF';
        }
        return;
      }
      requestAnimationFrame(fadeOut);
    }

    if (setMuted) {
      fadeOut();
    }
    else {
      video.volume = 1;
      muted = setMuted;
      video.muted = muted;

      if(audioText) {
        audioText.textContent = 'ON';
      }
    }
  }
}

export default ChangeAudioMute;
