import './project-item.scss';
import { ProjectModel } from "../../../models/Project";
import { useNavigate } from 'react-router-dom';
import { RouterName } from '../../../enum/RouterEnum';
import SwipeComponent from '../swipe';

interface Props {
  project: ProjectModel;
  index: number;
  handlePrevStep: () => void;
  handleNextStep: () => void;
  goToDetail: () => void;
  realIndex: number | null;
}

const ProjectMobileItem = (props: Props) => {
  const { project, index, goToDetail } = props;
  const navigate = useNavigate();

  const _changeShowDetail = () => {
    let charExits = /\W/g;
    const path = project.title.replaceAll(" ", "_").replace(charExits, '');
    navigate(`${RouterName.projectDetail}/${path}`, {
      state: project
    })
  };

  return (
    <div key={index} className={'project-list-home'}>
      <div className="projects-list-mobile-container">
        <div className='project-mobile-container' key={index} onClick={() => _changeShowDetail()}>
          <div className='project-mobile-border'></div>
          <div className='image-project-mobile'>
            <video
              autoPlay
              loop muted
              key={index}
              playsInline
              src={project.image}
            />
          </div>
        </div>
      </div>
      <SwipeComponent goToDetail={goToDetail}></SwipeComponent>
    </div>
  )


};

export default ProjectMobileItem;
