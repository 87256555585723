/*
  This class represents media elements like video textures rendered onto a 
  grid system in a Three.js scene. It uses GPGPU computation for advanced 
  graphical effects and interacts with a grid of cells that respond to user 
  mouse input. The media is dynamically resized and positioned based on the 
  element’s size and the current viewport.
*/

import * as THREE from 'three'
import GPGPU from '../../../ultils/gpgpu'
import { Size } from '../../../constants/types'
import { DeformationShader } from '../../../shaders/DeformationShader'
import { CellGridSystem } from './cellGrid'

interface Props {
  element: HTMLVideoElement
  scene: THREE.Scene
  sizes: Size
  renderer: THREE.WebGLRenderer
  mouse: THREE.Vector2
  isMobile: number
}

export default class Media {
  element: HTMLVideoElement         // The media video element
  scene: THREE.Scene                // The Three.js scene in which the media is rendered
  sizes: Size                       // This represents the width and height of the media
  videoTexture!: THREE.VideoTexture // Texture generated from the video element
  material!: THREE.ShaderMaterial   // Shader material for custom effects
  geometry!: THREE.PlaneGeometry    // Plane geometry to display the video
  renderer: THREE.WebGLRenderer     // The WebGL renderer used to display the scene
  mouse: THREE.Vector2              // Mouse position used for interaction
  uv: THREE.Vector2                 // UV coordinates for texture mapping
  gridSize!: THREE.Vector2          // Grid size for GPGPU and deformation effects
  mesh!: THREE.Mesh                 // Mesh that holds the video texture and shader material
  nodeDimensions!: Size             // Dimensions of the media element
  meshDimensions!: Size             // Scaled dimensions of the mesh
  elementBounds!: DOMRect           // DOM bounds of the video element
  gpgpu!: GPGPU                     // GPGPU instance for complex rendering tasks
  time: number                      // Timestamp for handling frame steps
  cgs: CellGridSystem               // Instance of the CellGridSystem for managing grid cells

  constructor({ element, scene, sizes, renderer, mouse, isMobile }: Props) {
    this.element = element
    this.scene = scene
    this.sizes = sizes
    this.renderer = renderer
    this.mouse = mouse
    this.uv = new THREE.Vector2()
    this.time = 0

    // Initialize the components and add the mesh to the scene
    this.createGeometry()
    this.createMaterial(isMobile)
    this.createMesh()
    this.setNodeBounds()
    this.setMeshDimensions()
    this.setMeshPosition()
    this.setTexture()
    this.createGPGPU()

    // Initialize the CellGridSystem for grid interaction
    this.cgs = new CellGridSystem(this.gridSize)
    this.scene.add(this.mesh)
  }

  // Create the geometry for the video plane
  createGeometry() {
    this.geometry = new THREE.PlaneGeometry(1, 1)
  }

  // Create the shader material for custom effects and set up the video texture
  createMaterial(isMobile: number) {
    this.material = new THREE.ShaderMaterial({
      uniforms: DeformationShader.uniforms,
      vertexShader: DeformationShader.vertexShader,
      fragmentShader: DeformationShader.fragmentShader,
      defines: {
        PR: Math.min(2, window.devicePixelRatio)
      }
    })
    this.material.uniforms.uMobile.value = isMobile
    this.videoTexture = new THREE.VideoTexture(this.element)
  }

  // Create the mesh using the plane geometry and shader material
  createMesh() {
    this.mesh = new THREE.Mesh(this.geometry, this.material)
  }

  // Set up the GPGPU system and calculate the grid size based on the viewport
  createGPGPU() {
    this.gpgpu = new GPGPU({
      renderer: this.renderer,
      scene: this.scene,
      sizes: this.sizes,
    })

    // Grid size is calculated based on the GPGPU size and viewport ratio
    this.gridSize = new THREE.Vector2(this.gpgpu.getSize() * this.sizes.width / this.sizes.height, this.gpgpu.getSize())
    this.material.uniforms.uGridSize.value = this.gridSize
  }

  // Get the bounds of the DOM element
  setNodeBounds() {
    this.elementBounds = this.element.getBoundingClientRect()

    this.nodeDimensions = {
      width: this.elementBounds.width,
      height: this.elementBounds.height,
    }
  }

  // Set the dimensions of the mesh based on the node and window size
  setMeshDimensions() {
    this.meshDimensions = {
      width: (this.nodeDimensions.width * this.sizes.width) / window.innerWidth,
      height: (this.nodeDimensions.height * this.sizes.height) / window.innerHeight,
    }

    this.mesh.scale.x = this.meshDimensions.width
    this.mesh.scale.y = this.meshDimensions.height
  }

  // Position the mesh within the scene
  setMeshPosition() {
    this.mesh.position.x = 0
    this.mesh.position.y = 0
  }

  // Set the texture and mouse interaction for the material
  setTexture() {
    this.material.uniforms.uMouse.value = this.mouse
    this.material.uniforms.uTexture.value = this.videoTexture

    // Optionally load a hover texture if available
    const textureHoverData = this.element.dataset.hover
    if (textureHoverData) {
      this.material.uniforms.uTextureHover.value = new THREE.TextureLoader().load(textureHoverData)
    }
  }

  // Handle resizing and update the mesh dimensions accordingly
  onResize(sizes: Size) {
    this.sizes = sizes

    this.setNodeBounds()
    this.setMeshDimensions()
    this.material.uniforms.uContainerResolution.value = new THREE.Vector2(window.innerWidth, window.innerHeight)
    const ratio = window.innerWidth / window.innerHeight
    const tempGridSize = new THREE.Vector2(Math.floor(this.gpgpu.getSize() * ratio), Math.floor(this.gpgpu.getSize()))
    this.material.uniforms.uGridSize.value = tempGridSize
    this.gridSize = tempGridSize
    this.cgs.setGridSize(this.gridSize);
    // console.log("Updating resolution: ", this.sizes)
  }

  // Handle mouse movement and update the GPGPU and grid system accordingly
  onMouseMove(uv: THREE.Vector2) {
    // console.log("Mouse position:", uv);
    this.gpgpu.updateMouse(uv)
    this.cgs.updateGrid(uv, 0)
  }

  // Render function called on each animation frame
  render(time: number) {
    const deltaTime = this.time - time
    this.time = time

    this.gpgpu.render(time, deltaTime)

    // Update the cells in the grid system
    this.cgs.updateCells()

    this.material.uniforms.uTime.value = this.time * 5.0

    // Update shader uniforms with the latest grid and GPGPU data
    this.material.uniforms.uCellData.value = this.cgs.getShaderData()
    this.material.uniforms.uGrid.value = this.gpgpu.getTexture()
  }
}
