import React, { useEffect } from 'react'
import { RandomReveal } from 'react-random-reveal';

interface Props {
  handlePrevStep: () => void;
  handleNextStep: () => void;
}

export default function LoadingCapabilities(props: Props) {
  const { handlePrevStep, handleNextStep } = props;

  useEffect(() => {
    const handleScroll = (e: any) => {
      e.preventDefault();
    };

    document.body.style.overflow = 'hidden';
    window.addEventListener('scroll', handleScroll, { passive: false });

    return () => {
      document.body.style.overflow = 'auto';
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const _renderContentItem1 = () => {
    return (
      <div className="home-loading-mobile">
        <p className='text-gray letter-scrambler'>{'< Loading Capabilities >'}</p>
        <p className="letter-scrambler">{'< loading > creative direction : OK'}</p>
        <p className="letter-scrambler">{'< loading > branding design: OK'}</p>
        <p className="letter-scrambler">{'< loading > interaction design : OK'}</p>
        <p className="letter-scrambler">{'< loading > character design : OK'}</p>
        <p className="letter-scrambler">{'< loading > motion design : OK'}</p>
        <p className="letter-scrambler">{'< loading > production : OK'}</p>
        <p className="letter-scrambler">{'< loading > front-end : OK'}</p>
        <p className="letter-scrambler">{'< loading > back-end : OK'}</p>
        <p className="letter-scrambler">{'< loading > ui.ux : OK'}</p>
        <p className="letter-scrambler">{'< loading > web 3.0 : OK'}</p>
        <p className='text-gray'>{'<>'}</p>
      </div>
    )
  };

  const _renderContentItem2 = () => {
    return (
      <div className="home-loading-mobile">
        <p className='text-gray letter-scrambler'>{'< Trust The Process >'}</p>
        <p className="letter-scrambler">{'To experiment is to invite challenges,'}</p>
        <p className="letter-scrambler">{'and sometimes, chaos.'}</p>
        <br></br>
        <p className="letter-scrambler">{"And now, we're inviting you to the lab"}</p>
        <p className="letter-scrambler">{"to watch it all happen. "}</p>
        <p className='text-gray'>{'<>'}</p>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
          <span className="letter-scrambler">{'Awaiting next steps ...'}</span>
          <span className="span-text-loading">{'>>>'}</span>
        </div>
      </div>
    )
  };

  return (
    <div className={'div-loading-container'}>
      <div className={`loading-first-mobile`}>
        {_renderContentItem1()}
        {_renderContentItem2()}
      </div>

    </div>
  )
};

