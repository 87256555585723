import { useLocation } from 'react-router-dom';
import gsap from 'gsap';
import TransitionContext from './TransitionContext';
import { useContext } from 'react';
import { SwitchTransition, Transition } from 'react-transition-group';

const TransitionComponent = ({ children, up }) => {
  const location = useLocation();
  const { toggleCompleted } = useContext(TransitionContext);
  return (
    <SwitchTransition>
      <Transition
        key={location.pathname}
        timeout={500}
        onEnter={(node) => {
          toggleCompleted(false);
          if (up) {
            gsap.set(node, { autoAlpha: 0, yPercent: -100 });
          }
          else {
            gsap.set(node, { autoAlpha: 0, yPercent: 100 });
          }
          gsap
            .timeline({
              paused: true,
              onComplete: () => toggleCompleted(true),
            })
            .to(node, { autoAlpha: 1, yPercent: 0, duration: 0.25 })
            .play();
        }}
        onExit={(node) => {
          if (up) {
            gsap
              .timeline({ paused: true })
              .to(node, { yPercent: -100, autoAlpha: 0, duration: 0.2 })
              .play();
          }
          else {
            gsap
              .timeline({ paused: true })
              .to(node, { yPercent: 100, autoAlpha: 0, duration: 0.2 })
              .play();
          }
        }}
      >
        {children}
      </Transition>
    </SwitchTransition>
  );
};

export default TransitionComponent;
