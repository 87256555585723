import './sider-right.scss';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../../redux/reducers';
import Lottie from 'react-lottie';
import * as animationData from '../../../assets/Json/FLOATINGMAN.json';
import {getSettingGifDefault} from "../../../ultils/getSettingGif";
import {useEffect, useRef, useState} from 'react';
import {ProjectModel} from '../../../models/Project';
import api from '../../../api';
import {useLocation} from 'react-router-dom';
import ListProject from './ListProject';
import {changeProjectList} from '../../../redux/actions/projectList';
import {RouterName} from '../../../enum/RouterEnum';
import { isSafari } from 'react-device-detect';
import ContactInfo from './ContactInfo';

const SidebarRight = () => {
  const ref = useRef<any>()
  const widthContent = useSelector((state: RootState) => state.sizeContent.width);
  const heightContent = useSelector((state: RootState) => state.sizeContent.height);
  const size = heightContent / 9 + widthContent / 16;
  const width = (widthContent - (heightContent * 16 / 15 + 160)) / 2;
  const dispatch = useDispatch();

  const projectList = useSelector((state: RootState) => state.projectList);
  const [listProject, setListProject] = useState<ProjectModel[]>([]);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const [idActive, setIdActive] = useState(0);

  useEffect(() => {
    if (location && location.state) {
      if (location.pathname.includes(RouterName.projectDetail)) {
        const name = location.pathname.replace(`${RouterName.projectDetail}/`, '');
        let charExits = /\W/g;
        const projectActive =  listProject.find(item =>  item.title.replaceAll(" ", "_").replace(charExits, '') === name);
        if(projectActive) {
          setIdActive(projectActive.id);
        }
      }
    } else {
      setIdActive(0);
    }
  }, [location, listProject]);

  useEffect(() => {
    // Sean Ngo: Commented this out because it is causing errors to the scrolling effect on the list of experiments
    //ref.current.addEventListener('wheel', handleWheel); 
    _getAllProject().then();
  }, []);

  const _getAllProject = async () => {
    setLoading(true);
    try {
      const res = await api.project.getAllProject();
      const listSort = res.data.sort((a: ProjectModel, b: ProjectModel) => a.order - b.order).filter(
        (item: ProjectModel) => item.status
      );;
      setListProject(listSort);
      dispatch(changeProjectList(listSort));
    } catch (err) {
      // If cannot retrieve from database, fallback to local saves
      setListProject(projectList);
    } finally {
      setLoading(false);
    }
  };



  const _renderImageItem = (className: string, icon: string) => {
    return (
      <div className={'gif-right-container'}>
        <img
          alt={''}
          className={className}
          src={icon}
        />
      </div>
    )
  };

  const _renderImageItem1 = (className: string) => {
    return (
      <div className={'gif-right-container'}>
        <div
          className={className}
        >
          <Lottie
            options={getSettingGifDefault(animationData)}
            width={'100%'}
            height={'auto'}
            isClickToPauseDisabled
          />
        </div>
      </div>
    )
  };

  return (
    <div ref={ref} className='sider-right' style={{
      width: width,
      fontSize: widthContent / 180,
      height: heightContent * 0.8,
      fontWeight: isSafari ? 500 : 700 // Sean Ngo: Safari looks weird with 500 font weight 
    }}>
      <ListProject idActive={idActive} listProject={listProject}/>
      <ContactInfo/>
    </div>
  )
};

export default SidebarRight;
  