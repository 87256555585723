import './list-project.scss';
import { useSelector } from 'react-redux';
import { RootState } from "../../../redux/reducers";
import { ProjectModel } from '../../../models/Project';
import { useNavigate } from 'react-router-dom';
import { RouterName } from '../../../enum/RouterEnum';
import { useEffect, useRef, useState } from 'react';
import { RandomReveal } from 'react-random-reveal';

interface Props {
  project: ProjectModel;
  index: number;
}

const ProjectItem = (props: Props) => {
  const { project, index } = props;
  const contentHeight = useSelector((state: RootState) => state.sizeContent.height);
  const [widthWithMargin, setWidthWithMargin] = useState(0);
  const videoReference = useRef<any>();
  const svgReference = useRef<any>();
  const navigate = useNavigate();

  const gotoDetail = () => {
    let charExits = /\W/g;
    const path = project.title.replaceAll(" ", "_").replace(charExits, '');
    navigate(`${RouterName.projectDetail}/${path}`, {
      state: project
    });
  };

  const checkAli = () => {
    let ali: 'left' | 'right' = 'left';
    switch (index % 4) {
      case 0:
      case 1:
        ali = 'right';
        break;
      case 2:
      case 3:
        ali = 'left';
        break;
      default:
        break;
    }
    return ali;
  };
  const align = checkAli();

  const [show, setShow] = useState(false);
  const [delayShow, setDelayShow] = useState(false);

  const updateVideoFilter = (isShow: boolean) => {
    if (videoReference.current && svgReference.current) {
      const video = videoReference.current as HTMLVideoElement;
      const svg = svgReference.current as HTMLElement;
      const pixelate = svg.firstElementChild;
      if (pixelate) {
        video.style.filter = isShow ? `url(#${pixelate.id})` : `none`;
      }
    }
  }

  useEffect(() => {
    if (contentHeight !== 0) {
      // Bean: 
      // Width / Size of the SVG Filter needs to be rounded to prevent repeating numbers
      // E.g if widthTd = 200.333333333, round to 200
      // This is to ensure that the filter works on all devices
      const widthTd = Math.round(contentHeight * 0.8 / 3);
      setWidthWithMargin(widthTd - 80);
    }
  }, [contentHeight]);

  const changeShowEffect = (isShow: boolean) => {
    if (widthWithMargin < 0) { return; }
    setShow(isShow);

    const svg = svgReference.current as HTMLElement;
    const spacing = svg.querySelector('#composite') as HTMLElement;
    const spacing2 = svg.querySelector('#composite2') as HTMLElement;
    const spacing3 = svg.querySelector('#composite3') as HTMLElement;
    const spacing4 = svg.querySelector('#composite4') as HTMLElement;
    const size = svg.querySelector('#morphology') as HTMLElement;
    const size2 = svg.querySelector('#morphology2') as HTMLElement;
    const size3 = svg.querySelector('#morphology3') as HTMLElement;
    const size4 = svg.querySelector('#morphology4') as HTMLElement;

    const startSpacingWidth = parseFloat(spacing?.getAttribute('width') || '1');
    const startSpacingHeight = parseFloat(spacing?.getAttribute('height') || '1');
    const startSizeRadius = parseFloat(size?.getAttribute('radius') || '0');

    const d = widthWithMargin * 0.25;
    const r = d * 0.5;
    const targetSpacingWidth = isShow ? d : 1;
    const targetSpacingHeight = isShow ? d : 1;
    const targetSizeRadius = isShow ? r : 0;

    // Animation duration in milliseconds
    const duration = 300;
    const steps = 5; // Number of steps
    const stepDuration = duration / steps; // Time per step
    const startTime = performance.now();

    const animate = (time: number) => {
      const elapsed = time - startTime;
      const currentStep = Math.min(Math.floor(elapsed / stepDuration), steps); // Determine current step
      const progress = currentStep / steps; // Calculate progress based on steps
      // // Interpolate values
      const currentWidth =
        startSpacingWidth + (targetSpacingWidth - startSpacingWidth) * progress;
      const currentHeight =
        startSpacingHeight + (targetSpacingHeight - startSpacingHeight) * progress;
      const currentRadius =
        startSizeRadius + (targetSizeRadius - startSizeRadius) * progress;
      // Update attributes
      spacing?.setAttribute('width', Math.abs(currentWidth).toString());
      spacing?.setAttribute('height', Math.abs(currentHeight).toString());
      spacing2?.setAttribute('width', Math.abs(currentWidth).toString());
      spacing2?.setAttribute('height', Math.abs(currentHeight).toString());
      spacing3?.setAttribute('width', Math.abs(currentWidth).toString());
      spacing3?.setAttribute('height', Math.abs(currentHeight).toString());
      spacing4?.setAttribute('width', Math.abs(currentWidth).toString());
      spacing4?.setAttribute('height', Math.abs(currentHeight).toString());

      size?.setAttribute('radius', currentRadius.toString());
      size2?.setAttribute('radius', currentRadius.toString());
      size3?.setAttribute('radius', currentRadius.toString());
      size4?.setAttribute('radius', currentRadius.toString());

      // Continue animation if not complete
      if (progress < 1) {
        if (isShow) {
          updateVideoFilter(isShow);
        }
        requestAnimationFrame(animate);
      }
      else {
        updateVideoFilter(isShow);
      }
    };

    // Start the animation
    requestAnimationFrame(animate);
  };

  const _renderTextItem = (text: string, isLast?: boolean) => {
    return (
      <span style={{ marginRight: isLast ? '0' : '10px', position: 'relative', overflowWrap: 'break-word' }}>
        <RandomReveal
          isPlaying
          key={text}
          duration={1}
          characters={text}
          revealDuration={1}
          characterSet={["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]}
        />
      </span>
    )
  }

  useEffect(() => {
    if (show) {
      setTimeout(() => { setDelayShow(true) }, 500);
    }
    setDelayShow(false);
  }, [show]);

  const _renderImageProject = () => {
    return (
      <div
        className={'image-project-box'}
        onClick={gotoDetail}
        onMouseEnter={() => changeShowEffect(true)}
        onMouseLeave={() => changeShowEffect(false)}
      >
        {/* <LottiePlayer link={project.image} className={'lottie-player-container2'} /> */}
        <svg ref={svgReference}
          style={{ position: 'absolute', top: '0', left: '0', display: 'block' }}
          viewBox={`0, 0, ${widthWithMargin}, ${widthWithMargin}`}>

          <filter id={`pixelate-${index}`} x="0" y="0" width="100%" height="100%">
            <feFlood id="dot" x={widthWithMargin * 0.5} y={widthWithMargin * 0.5} height="1" width="1" />
            <feComposite id="composite" width="1" height="1" />
            <feTile result="tiled" />
            <feOffset in="tiled" dx={-widthWithMargin * 0.5} dy={-widthWithMargin * 0.5} result="alignedTiled1" />

            <feFlood id="dot2" x="0" y={widthWithMargin * 0.5} height="1" width="1" />
            <feComposite id="composite2" width="1" height="1" />
            <feTile result="tiled2" />
            <feOffset in="tiled2" dx={widthWithMargin * 0.5} dy={-widthWithMargin * 0.5} result="alignedTiled2" />

            <feFlood id="dot3" x={widthWithMargin * 0.5} y="0" height="1" width="1" />
            <feComposite id="composite3" width="1" height="1" />
            <feTile result="tiled3" />
            <feOffset in="tiled3" dx={-widthWithMargin * 0.5} dy={widthWithMargin * 0.5} result="alignedTiled3" />

            <feFlood id="dot4" x="0" y="0" height="1" width="1" />
            <feComposite id="composite4" width="1" height="1" />
            <feTile result="tiled4" />
            <feOffset in="tiled4" dx={widthWithMargin * 0.5} dy={widthWithMargin * 0.5} result="alignedTiled4" />

            <feComposite in="SourceGraphic" in2="alignedTiled1" operator="in" />
            <feMorphology id="morphology" operator="dilate" radius="0" result="dilatedPixelation" />

            <feComposite in="SourceGraphic" in2="alignedTiled2" operator="in" />
            <feMorphology id="morphology2" operator="dilate" radius="0" result="dilatedPixelation2" />

            <feComposite in="SourceGraphic" in2="alignedTiled3" operator="in" />
            <feMorphology id="morphology3" operator="dilate" radius="0" result="dilatedPixelation3" />

            <feComposite in="SourceGraphic" in2="alignedTiled4" operator="in" />
            <feMorphology id="morphology4" operator="dilate" radius="0" result="dilatedPixelation4" />

            <feFlood id="dotBackground" x="0" y="0" height="1" width="1" />
            <feComposite id="compositeBackground" width="2" height="2" />
            <feTile result="fullTileBackground" />
            <feComposite in="SourceGraphic" in2="fullTileBackground" operator="in" />
            <feMorphology id="morphologyBackground" operator="dilate" radius="1" result="dilatedBackground" />

            <feMerge result="mergedResult">
              <feMergeNode in="dilatedBackground"></feMergeNode>
              <feMergeNode in="dilatedPixelation"></feMergeNode>
              <feMergeNode in="dilatedPixelation2"></feMergeNode>
              <feMergeNode in="dilatedPixelation3"></feMergeNode>
              <feMergeNode in="dilatedPixelation4"></feMergeNode>
            </feMerge>

          </filter>

        </svg>
        <video id='video-player' className='video-player' ref={videoReference}
          autoPlay
          loop muted
          key={index}
          src={project.image}
        />
        {show
          ?
          <button
            className='project-button'
            style={{
              fontSize: `${widthWithMargin / 20}px`,
            }}
          >
            <div className='button-line'></div>
            {delayShow && _renderTextItem(project.detailTitle.toUpperCase(), true)}
          </button>
          : <></>
        }

      </div>
    )
  };

  return (
    <div className={'card-box-container'}>
      <div
        className='card-item-container'
        style={{
          flexDirection: align === 'right'
            ? 'row'
            : 'row-reverse',
        }}>
        {/* {_renderInfoLeft()} */}
        {widthWithMargin > 0 && _renderImageProject()}
        {/* <div className='overlay'>
          <div className={'overlay-bg'}>
          </div>
          <button
            className="project-button"
            onClick={gotoDetail}
            style={{
              fontSize: widthContent / 130
            }}
          >
            VIEW THE WORK
          </button>
        </div> */}
      </div>

    </div>
  )
};

export default ProjectItem;
