import { processGrayscaleImage } from "./ImageProcessor";
import TOLText from "../../src/assets/image/TOLTEXT.png"

export class GridRenderSingleton {
  private static instance: GridRenderSingleton;
  public dimX: number;
  public dimY: number;
  public totalCells: number;
  private shuffledIndices: number[];
  private rectRefStr: string;
  private rectRef: NodeListOf<SVGRectElement> | null;
  private canvasRef: HTMLCanvasElement | null;
  public context!: WebGLRenderingContext | WebGL2RenderingContext;

  private constructor(_imageSrc: string, _rectRef: string) {
    this.dimX = Math.ceil(Math.sqrt(4096)) * 0.5;
    this.dimY = this.dimX * 0.5;
    this.totalCells = this.dimX * this.dimY;
    this.shuffledIndices = Array(this.totalCells);
    this.rectRefStr = _rectRef;
    this.rectRef = document.querySelectorAll(this.rectRefStr);
    this.canvasRef = document.getElementById('webgl') as HTMLCanvasElement;

    this.fetchGrayscaleValues(_imageSrc);
  }

  public static getInstance(_imageSrc: string = TOLText, _rectRef: string = '.grid-rect'): GridRenderSingleton {
    if (!GridRenderSingleton.instance) {
      GridRenderSingleton.instance = new GridRenderSingleton(_imageSrc, _rectRef);
    }

    return GridRenderSingleton.instance;
  }

  public getIndices() {
    return this.shuffledIndices;
  }

  public updateRectRef() {
    this.rectRef = document.querySelectorAll(this.rectRefStr);
    this.canvasRef = document.getElementById('webgl') as HTMLCanvasElement;
  }

  public hideRect() {
    if (this.rectRef) {
      this.rectRef.forEach((rect, index) => {
        rect.setAttribute('opacity', '0');
      });
      return true;
    }
    else {
      console.warn("Rect Reference does not exist");
      return false;
    }
  }

  public transition(start: number, end: number, delta: number, duration: number) {
    const t = Math.min(delta / duration, 1);
    const value = start + (end - start) * t;
    const cellsToReveal = Math.floor(value * this.totalCells);
    // console.log("Time: %f", delta);
    // console.log("Transition: %f%", cellsToReveal / this.totalCells * 100);

    if (this.rectRef) {
      this.rectRef.forEach((rect, index) => {
        const newOpacity = this.shuffledIndices[index] < cellsToReveal ? '1' : '0';
        rect.setAttribute('opacity', newOpacity);
      });
    }

    // if (this.canvasRef) {
    //   const canvas = this.canvasRef;
    //   const ctx = canvas.getContext('2d');
    //   if (ctx) {
    //     ctx.clearRect(0, 0, canvas.width, canvas.height);

    //     for (let y = 0; y < this.dimY; y++) {
    //       for (let x = 0; x < this.dimX; x++) {
    //         const index = y * this.dimX + x;
    //         const newOpacity = this.shuffledIndices[index] < cellsToReveal ? '1' : '0';

    //         ctx.fillStyle = `rgba(255, 255, 255, ${newOpacity})`; // White fading out
    //         ctx.fillRect(x * this.dimX, y * this.dimY, this.dimX, this.dimY);
    //       }
    //     }
    //   }
    // }
  }

  public fadeIn(timer: number = 0.0, duration: number) {
    this.transition(0, 1, timer, duration); // Any callback function
    // Main transition logic
    if (timer > duration) {
      return true;
    }
    return false;
  }

  public fadeOut(timer: number = 0.0, duration: number) {
    this.transition(1, 0, timer, duration); // Any callback function
    if (timer > duration) {
      return true;
    }
    return false;
  }

  private shuffleArray(array: number[]): number[] {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  // Sort grayscale values and return their indices in the sorted order
  private sortValuesByIndex(values: number[]): number[] {
    const indexedValues = values.map((value, index) => ({ value, index }));
    indexedValues.sort((a, b) => b.value - a.value);

    const sortedIndices = new Array(values.length);
    let currentIndex = 0;

    // Handle ties in value by assigning the same index to tied elements
    for (let i = 0; i < indexedValues.length; i++) {
      if (i === 0 || indexedValues[i].value !== indexedValues[i - 1].value) {
        currentIndex = i;
      }
      sortedIndices[indexedValues[i].index] = currentIndex;
    }

    return sortedIndices;
  }

  private async fetchGrayscaleValues(imageSrc: string) {
    try {
      // Process image and get sorted indices based on grayscale values
      const values = await processGrayscaleImage(imageSrc, this.dimX, this.dimY);
      this.shuffledIndices = this.sortValuesByIndex(values);
    } catch (error) {
      // Fallback to a shuffled array if image processing fails
      const indices = Array.from({ length: this.totalCells }, (_, index) => index);
      this.shuffledIndices = this.shuffleArray(indices);
    }
  }

}

export default GridRenderSingleton;