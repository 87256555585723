import { useEffect } from "react";
import { Layout } from "antd";
import './mobile-layout.scss';
import * as animationData from '../../assets/Json/BLOB.json';
import Lottie from 'react-lottie';
import '../../component/Mobile/Home/home-mobile.scss';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Outlet, useNavigate } from "react-router-dom";
import { RouterName } from "../../enum/RouterEnum";
import api from "../../api";
import { useDispatch } from "react-redux";
import { changeProjectList } from "../../redux/actions/projectList";
import { ProjectModel } from "../../models/Project";
import { LetterScrambleSingleton } from "../../ultils/LetterScramble";

const { Content } = Layout;

const Mobile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      const letterScrambler = LetterScrambleSingleton.getInstance();
      // console.log(letterScrambler);
      letterScrambler.startRandomOperations();
    }, 1000);
  }, []);

  useEffect(() => {
    const handleScroll = (e: any) => {
      e.preventDefault();
    };

    document.body.style.overflow = 'hidden';
    window.addEventListener('scroll', handleScroll, { passive: false });

    return () => {
      document.body.style.overflow = 'auto';
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    _getListProject().then();
  }, [])

  const _getListProject = async () => {
    try {
      const res = await api.project.getAllProject();
      const listSort = res.data.sort((a: ProjectModel, b: ProjectModel) => a.order - b.order).filter(
        (item: ProjectModel) => item.status
      );
      dispatch(changeProjectList(listSort));
    } catch (err) {
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const _goToDashboard = () => {
    navigate(RouterName.dashboard);
  };

  const _renderHeaderMobile = () => {
    return (
      <div className={'header-mobile-container'}>
        <div className="logo-mobile-container" onClick={_goToDashboard}>
          <Lottie
            options={defaultOptions}
            width={'70%'}
            height={'auto'}
            isClickToPauseDisabled
          />
        </div>
        <div className={'title-header letter-scrambler'}>THE OTHER LAB CO.</div>
      </div>
    )
  };

  return (
    <Layout className="mobile-container">
      <header className="header-mobile">
        {_renderHeaderMobile()}
        <div className="header-mobile-border"></div>
      </header>
      <Content className="content-mobile">
        <Outlet></Outlet>
      </Content>
    </Layout>
  )
};
export default Mobile;
